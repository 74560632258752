
.active{
  background-color: #1976d2;
  color: white;
  display: flex;
  text-decoration: none;
}
a{
  text-decoration: none;
  color: black;
}